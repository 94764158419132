<template>
  <ModalTemplate
    :showModal="isModalActive"
    width="839px"
    @closeModal="closeModal"
  >
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div class="flex-center-vertical">
          <Location size="26" />
          <p class="averta-bold ml-1 label-18">Cari Alamat</p>
        </div>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div class="body-maps">
        <div class="container-search-address">
          <!-- input search address -->
          <b-field>
            <b-autocomplete
              rounded
              icon="magnify"
              placeholder="Telusuri Alamat"
              v-model="inputSearch"
              field="title"
              :data="map.address"
              :loading="map.isFetchingAddress"
              @keyup.native.enter="fetchDataMaps(inputSearch)"
              @select="handleAddressSelect"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.name }}
                    <br>
                    <small> {{ props.option.address }} </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <div v-if="map.isFetchingAddress" class="loading-spinner-wrapper">
            <SpinnerLoading :isBlack="true" />
          </div>
        </div>
        <!-- Maps -->
        <GmapMap
          map-type-id="roadmap"
          :zoom="15"
          :center="map.gmapCenter"
          style="width: auto; height: 450px"
          :options="mapOptions"
          @click="setMarker"
        >
          <GmapInfoWindow
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
          </GmapInfoWindow>
          <GmapMarker
            :position="map.markerPosition"
            :clickable="true"
            @dragend="updateCoordinate"
            :draggable="false">
          </GmapMarker>
        </GmapMap>
        <div class="button-select-wrapper mt-5">
          <div class="is-flex"
            :class="[map.addressSelected ? 'is-justify-content-space-between' : 'is-justify-content-end']"
          >
            <div class="is-flex is-align-items-center c-w-79" v-if="map.addressSelected">
              <Location class="mr-2" size="24" color="#E10009" />
              <div>
                <p class="label-14 averta-bold">
                  {{ map.addressSelected.name }}
                </p>
                <p class="label-12 text-grey">
                  {{ map.addressSelected.address }}
                </p>
              </div>
            </div>
            <div class="flex-center-vertical">
              <ButtonApp :isDisabled="!map.addressSelected" @click="handleConfirmAddress">
                <p class="averta-bold py-1 px-5">Pilih</p>
              </ButtonApp>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line no-unused-vars
import { alertErrorMessage } from '@/helpers'
import { Location } from '@/components/icons'
import SpinnerLoading from '@/components/loading/SpinnerLoading.vue'

export default {
  name: 'ModalMapsAddress',
  components: {
    Location,
    SpinnerLoading,
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    ModalTemplate: () => import('@/components/modal/ModalTemplate.vue')
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    refreshData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      isLoading: false,
      dataModal: null,
      inputSearch: '',
      selectedAddress: null,
      // state maps
      addressRef: '',
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      map: {
        currentPlace: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        markerPosition: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        address: null,
        isFetchingAddress: false,
        addressSelected: {},
        gmapCenter: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        mapSelectedIsShow: false
      },
      mapOptions: {
        mapTypeControl: false,
        fullscreenControl: false
      },
      // end state maps
      dataMaps: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    setMarker (event) {
      this.map.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      this.updateCoordinate(event)
    },
    closeModal () {
      this.$emit('closeModal')
    },
    handleSelectAddress (params) {
      this.selectedAddress = params
    },
    handleConfirmAddress () {
      const res = { data: this.map.addressSelected, currentPlace: this.map.currentPlace }
      this.$store.commit('client/setDataMaps', res)
      this.$emit('selectAddress', res)
      this.$emit('closeModal')
    },
    /** func for maps
     * fetch data for search address
     * refresh maps, update coordinate etc
     */
    fetchDataMaps (val) {
      if (!val) {
        this.map.address = []
        return
      }

      this.map.isFetchingAddress = true

      const payload = {
        url: this.url,
        keyword: val
      }

      this.$store
        .dispatch('client/searchAddressGMap', payload)
        .then(response => {
          this.map.address = response.data.data
          this.map.isFetchingAddress = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch(error => {
          this.map.isFetchingAddress = false
          // alertErrorMessage(error)
        })
    },
    refreshAllCoordinate (newVal) {
      this.infoWinOpen = false
      this.map.gmapCenter = {
        lat: newVal.lat,
        lng: newVal.lon
      }

      this.map.markerPosition = {
        lat: newVal.lat,
        lng: newVal.lon
      }

      this.map.currentPlace.lat = newVal.lat
      this.map.currentPlace.lng = newVal.lon

      const latLngObj = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.$geocoder.send(latLngObj, response => {
        this.addressRef = response.results[0].formatted_address
      })
    },
    getAddressMap (location) {
      const latLngObj = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }

      this.$geocoder.send(latLngObj, response => {
        const result = response.results[0].formatted_address
        this.addressRef = result
        this.map.addressSelected.address = result
        this.map.addressSelected.name = ''
        this.inputSearch = result
      })
    },
    updateCoordinate (location) {
      this.map.currentPlace.lat = location.latLng.lat()
      this.map.currentPlace.lng = location.latLng.lng()
      this.getAddressMap(location)
    },
    handleAddressSelect (option) {
      this.map.addressSelected = option

      this.map.gmapCenter = { lat: option.lat, lng: option.lon }
      this.map.markerPosition = { lat: option.lat, lng: option.lon }

      // RESET
      this.map.address = []
    }
  },
  watch: {
    showModal (value) {
      this.isModalActive = value
      this.selectedMenu = null
    },
    'map.addressSelected' (newVal) {
      this.refreshAllCoordinate(newVal)
    },
    inputSearch (val) {
      if (val === '') {
        this.fetchDataMaps('')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.body-maps {
  background: white;
  border-radius: 0px 0px 12px 12px;
}

.button-select-wrapper {
  height: 100%;
  padding: 10px;
  box-shadow: 0px -3px 30px 0px #00000012;
}

.container-search-address {
  position: absolute;
  top: 75px;
  left: 0px;
  z-index: 1000;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.loading-spinner-wrapper {
  z-index: 10;
  position: absolute;
  right: 20px;
  top: 21px;
}

</style>
